const BlogContent = [
    {
        images: '01',
        title: 'Mobil UI Design Process Using Adobe XD',
        category: 'Mobil UI Design'
    },
    {
        images: '02',
        title: '3DS Max Modeling & Corona Rendering',
        category: 'Create 3D NFT'
    },

    {
        images: '03',
        title: 'Create Scene & Texture Map',
        category: 'Interrior Design & Rendering'
    }
]

export default BlogContent;