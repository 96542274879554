import React, {Component} from "react";
import Slider from "react-slick";
import {portfolioSlick2} from "../../../page-demo/script";
import {Link} from "react-router-dom";
import PortfolioList from '../../../data/portfolio';

class Portfolio extends Component {
    render() {
        let title = 'Our Works',
            description = 'Explore a spectrum of expertise tailored to meet your needs. Our professional services encompass';
        return (
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value, index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4 className="title"><Link to={`/portfolio-details/${value.titleUrl}`}>{value.title}</Link></h4>
                                            <div className="portfolio-button">
                                                <Link className="rn-btn" to={`/portfolio-details/${value.titleUrl}`}>Case Study</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <Link className="link-overlay" to={`/portfolio-details/${value.titleUrl}`}></Link>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Portfolio;