import React from "react";

export const ThreeDIcon = ({}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50.482" height="53.67" viewBox="0 0 50.482 53.67">
            <g id="Layer_x0020_1" transform="translate(0 0.256)">
                <path id="Path_1" data-name="Path 1" d="M49.165,526.9c0,.592.117.587.1,1.022-.061,2.006-.946,4.77,1.179,4.807a4.9,4.9,0,0,1,2.287.3,6.515,6.515,0,0,0,1.685.772,3.367,3.367,0,0,1,.9.392c.2.125.154.115.388.258s.214.115.417.229l10.956,7.016c.287.224.444.376.716.577.856.629,1.118.229,1.645.942,2.447,3.3,3.627.366,4.152-.161.458-.459,3.1-1.848,4.279-2.574.335-.206.791-.335,1.162-.519.41-.2.761-.383,1.205-.605l3.37-1.673a24.209,24.209,0,0,1,3.98-1.321c.43-.075,2.175-.617,2.175-1.445,0-.422-.618-.713-.905-.905a9.565,9.565,0,0,0,.253-2.85c-.008-1.446.523-1.15.523-2.71-.744-.173-.088-.258-1.422-.258-.254,0-1.035.579-1.035.776,0,.783.265.7.247,1.493a8.367,8.367,0,0,0,.165,2.869c.085.634.523.619-.411,1.371a13.488,13.488,0,0,1-3.489,1.638c-1.808.708-1.787.923-3.065,1.46l-2.063.911c-1.257.6-4.373,1.907-5.21,2.418l-1.521.826c-1.183.518-.941-1.106-.849-1.772.061-.443.013-.819.036-1.258.109-2.174,1.107-4.655-1.551-4.655-1.077,0-.905,1.527-.905,1.939,0,.785.915-.084.648,2.845-.065.714-.132.485-.26,1.034-1.094,0-1.6-.62-2.3-1.063-.378-.239-.712-.352-1.091-.59a26.683,26.683,0,0,0-2.991-2.051c-1.719-.621-3.015-2.021-4.275-2.578l-5.507-2.9a3.059,3.059,0,0,0-1.293-.517C50.77,527.28,53,525.86,50.2,525.86a1.239,1.239,0,0,0-1.035,1.035Z" transform="translate(-45.803 -491.342)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_2" data-name="Path 2" d="M47.507,10.47c0,1.7.277.4.262,2.195-.015,1.838.315,2.138-.146,3.61-.285.908-1.358,2.6.919,2.6.466,0,.845-.783.995-1.2.31-.874.046-1.1.034-2.024l.121-4.041c.29-.773,1.466-.54,2.826-1.686a6.547,6.547,0,0,1,1.544-.783l7.626-3.882A32.638,32.638,0,0,0,64.55,3.592a21.841,21.841,0,0,1,3.386-1.268c.607,0,.517.092.517,1.035a12.289,12.289,0,0,1-.124,1.686c-.172,1.624-1.34,1.258-.886,2.543.416,1.178,2.261.843,2.574.568a2.925,2.925,0,0,0,.426-.752c.22-.779-.608-.812-.5-1.644.051-.382.128-.5.206-.958a8.533,8.533,0,0,0,.289-2.862c1.188.1,2.547.992,3.915,1.72.387.206.623.4.953.6l3.043,1.87c.67.486,1.212.909,1.864,1.369.633.446,1.222.876,1.853,1.379.86.686,2.018,2.757,3.708,2.757.549,0-.062-.131.646-.388.4,1.7-.316,5.3-.217,5.637a1.713,1.713,0,0,0,1.251.828,1.528,1.528,0,0,0,1.293-1.552c0-.621-.388-.671-.388-1.293a17.938,17.938,0,0,1,.172-3.582c.349-2.135-.953-1.376-2.037-1.664a6.512,6.512,0,0,1-1.315-.625c-2.137-1.294-4.57-3.21-7.059-4.707-.45-.27-.77-.421-1.217-.722a17.771,17.771,0,0,1-2.794-1.99c-.43-.393-.175-.138-.654-.38-1.192-.6-.584-1.328-2.938-1.328a7.882,7.882,0,0,0-1.234-.1c-.343.065-.3.127-.651.181A27.336,27.336,0,0,0,65.166,1.75c-1.526.681-5.385,3.088-6.17,3.4-1.1.433-.691.464-1.324.745-1.782.791-.606-.132-4.383,1.952a11.59,11.59,0,0,1-3.119,1.018c-.826.215-.7.52-1.242.827-.48.272-1.421.2-1.421.777Z" transform="translate(-44.016 0)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_3" data-name="Path 3" d="M200.319,154.473c-.206,2.02,1.885.791,2.893.656,1.08-.145,1.623-.4,2.675-.49a12.82,12.82,0,0,1,2.374-.495c.154.1.614.439.612.734,0,.335-.494.631-.8.716l-1.232.785a11.229,11.229,0,0,1-3.361,1.575c-.588.211-2.108.262-2.027.868.059.438.657,1.216.94,1.179,2.379-.319,2.062-1.253,4.348-.27a4.291,4.291,0,0,1,2.309,1.8c.49,1.066.385.4.557,1.681.051.384-.041.221-.2.424l-1.309.955c-4.847,2.117-3.8-.725-7.232.574.327,2.446,3.4,1.632,5.4,1.364,3.127-.419,6.039-1.938,4.1-5.524-.807-1.5-3.214-2.374-3.665-2.884a12.668,12.668,0,0,1,1.107-.65,10.918,10.918,0,0,1,1.188-.7,2.684,2.684,0,0,0,1.514-2.62c-.154-1.151-1.172-1.347-2-1.4a30.776,30.776,0,0,0-8.2,1.728Z" transform="translate(-187.105 -142.809)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_4" data-name="Path 4" d="M430.648,170.22c0,2.217-1.908,3.922-3.663,4.746a8,8,0,0,1-2.18.67c-1.174,0-.431-2.3-.422-2.7.019-.866.056-1.357.122-2.028.086-.872-.183-.907-.033-1.867.519-3.309-.758-4.191,1.046-3.681,2.378.673,5.13,4.058,5.13,4.862Zm-8.552-4.276c0,.9.428,1.555.428,2.708a27.779,27.779,0,0,1-.179,4.524c-.06.3-1.408,2.095-.173,3.381.927.965,1.2.788,2.633.788a8.671,8.671,0,0,0,4.958-2.026c.39-.37.4-.576.8-.909.952-.8,1.082-.8,1.476-1.945.885-2.572-.061-3.214-1.023-4.611-2-2.907-6.859-5.706-8.527-3.655a3.614,3.614,0,0,0-.394,1.744Z" transform="translate(-393.868 -152.856)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_5" data-name="Path 5" d="M613.834,364.369c0,1.933,1.54,1.278,2.894,4.282a3.933,3.933,0,0,1,.266.771c.111.53.042.189-.008.781-.621-.051-.55-.18-.963-.459a9.968,9.968,0,0,1-2.625-1.9c-.725-.656-2.9-2.1-4.127-1.612-.961.378-.739,1.062-.249,1.85.235.378.4.571.6.953l.905,2.068c1.361,2.362.353,2.978,2.453,2.978a.933.933,0,0,0,.776-1.035c0-.593-.687-1.376-.942-1.9l-.868-1.975c.7.058,1.96,1.292,2.638,1.757.472.324.948.373,1.475.724.812.54,2.354,1.166,2.789-.1a6.7,6.7,0,0,0,.132-1.413c.083-1.119-1.427-2.878-2.031-4.027-.867-1.647-3.112-2.547-3.112-1.737Z" transform="translate(-568.499 -340.21)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_6" data-name="Path 6" d="M2.457,339.024c.119-.448.763-1.019,1.189-1.656a4.715,4.715,0,0,1,.652-.77c.231-.256.416-.548.615-.808l1.982-2.285c.316-.6.091-.671.864-.688a1.62,1.62,0,0,0,.278.627c.822,1.6,0,4.016-.661,5.326a3.794,3.794,0,0,1-2.313,1.5c-.053,0-2.239-.7-2.605-1.25ZM0,339.542c0,.586.521.635.98.83a8.852,8.852,0,0,1,1.168.772l3.154.725a3.123,3.123,0,0,0,1.254-.3,4.819,4.819,0,0,0,2.867-3.34c.088-.594.157-.872.241-1.311.35-1.824.536-3.028-.326-4.647-.826-1.551-2.86-.611-2.862-.61-1.645.869-2.016,1.954-3.115,3.479L2.236,336.6a7.844,7.844,0,0,1-1.168,1.418c-.331.327-1.067.967-1.067,1.519Z" transform="translate(0 -309.677)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_7" data-name="Path 7" d="M152.928,419.612c0,.685-.727,1.1-1.163,2.587a10.267,10.267,0,0,1-.48,1.33c-.648,1.5-.886,1.423-.009,2.389.677.746.678,1.323,3.074,1.323,1.2,0,3.917-.772,4.526-1.681-.02-.023-.047-.094-.056-.073s-.045-.058-.058-.071c-.907-.934-2.3.144-4.153.144-.691,0-1.681-.212-1.681-.905,0-2.208,3.491-.281,3.491-1.81a1.878,1.878,0,0,0-.129-.646,4.849,4.849,0,0,0-2.069-.388c.014-.631.024-.432.687-1.253.194-.24.226-.354.577-.347,1.379.026,4.425,2.022,4.425.7,0-1.159-.248-1.052-2.86-1.794-1.353-.384-.72-.19-1.384-.684-.481-.357-2.035-.911-2.638,0C152.664,418.97,152.928,419.047,152.928,419.612Z" transform="translate(-140.774 -390.523)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_8" data-name="Path 8" d="M492.5,419.072c0,1.654.061,1.844,1.452,3.074,1.74,1.539,2.452,2.022,4.8.717,1.8-1,2.441-1.779,2.068-3.443-.135-.6-.181-.75-1.094-1.105a5.293,5.293,0,0,0-2.59.217c-1.682.5-1.258,1.833-.365,1.833,1.321,0,1.653-.508,2.327-.129-.146.545-1.686,2.23-3.311,1.113a2.623,2.623,0,0,1-1.293-3c.941-4.1,3.181-1.54,3.181-3.287,0-.757-1.094-1.035-1.939-1.035-.9,0-1.83,1.261-2.29,1.848a4.291,4.291,0,0,0-.943,3.195Z" transform="translate(-460.047 -386.881)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_9" data-name="Path 9" d="M302.18,439.265v.776c0,1.015,2.383,1.479,2.938,1.717a4.617,4.617,0,0,1,1.07.482c-.227.975-.775,1.22-1.643,1.589-1.214.516-2.677.944-1.857,1.653.931.805,3.977-.651,4.515-1.452,2.236-3.325-2.825-3.737-2.825-4.635,0-.449,1.654-1.645,2.182-1.825.776-.265.791-.186.791-1.019,0-.863-1.685-1.407-3.307.7l-1.408,1.437c-.238.183-.457.21-.457.577Z" transform="translate(-282.268 -407.203)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_10" data-name="Path 10" d="M421.871,455.886c0,1.253,1.242,1.1.886,4.248a5.257,5.257,0,0,0-.113,1.309c.142,1.878,2.459,1.818,2.459.391,0-1.073-1.15.6-.552-3.656a6.393,6.393,0,0,0-.14-2.117c-.189-.8-1.017-.821-1.894-.821a.733.733,0,0,0-.646.646Z" transform="translate(-394.071 -425.375)" fill="#f9004d" fill-rule="evenodd"/>
            </g>
        </svg>


    )
}