export default [
    {
        id: 1,
        image: 'image-1',
        imageUrl:'/assets/images/portfolio/volvo1.jpg',
        category: 'AI Generate & Graphic Design',
        title: ' Industrial Design',
        titleUrl: 'Industrial-Design'
    },
    {
        id: 2,
        image: 'image-2',
        imageUrl:'/assets/images/portfolio/gr2.jpg',
        category: 'AI Generate & Graphic Design',
        title: ' AI Create Album Cover',
        titleUrl: 'AI-Create-Album-Cover'
    },
    {
        id: 3,
        image: 'image-3',
        imageUrl:'/assets/images/portfolio/gr3.jpg',
        category: 'Mobile App UI & UX',
        title: ' Travel Guide App Project',
        titleUrl: 'Travel-Guide-App-Project'
    },
    {
        id: 4,
        image: 'image-4',
        imageUrl:'/assets/images/portfolio/intersection4.jpg',
        category: 'Brand Design & Packing',
        title: ' Olive Oil Packing Design',
        titleUrl: 'Olive-Oil-Packing-Design'
    },
    {
        id: 5,
        image: 'image-5',
        imageUrl:'/assets/images/portfolio/call5.jpg',
        category: 'AI Generate & Graphic Design',
        title: ' AI Create Album Cover',
        titleUrl: 'AI-Create-Album-Cover'
    }
]