import React, {Component, useRef, useState} from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {Link, useParams} from "react-router-dom";
import PortfolioList from '../data/portfolio';
import { Element } from 'react-scroll';

function PortfolioDetails() {
    const {id} = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const portfolio = PortfolioList.find(portfolio => portfolio.titleUrl.toString() === id);
    const portfolios = PortfolioList.filter(portfolio => portfolio.titleUrl.toString() !== id).sort(() => Math.random() > 0.5 ? 1 : -1).slice(0, 2);

    const openModal = () => {
        setIsOpen(true)
    }

    return (
        <React.Fragment>
            <PageHelmet pageTitle='Portfolio Details' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"  data-black-overlay="7">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title">{portfolio.title}</h2>
                                <p>{portfolio.category}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Portfolio Details */}
            <div className="rn-portfolio-details ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-details">
                                <div className="inner">
                                    <h2>Trydo</h2>
                                    <p className="subtitle">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commod viverra maecenas accumsan lacus vel facilisis. ut labore et dolore magna aliqua. </p>

                                    <div className="portfolio-view-list d-flex flex-wrap">
                                        <div className="port-view">
                                            <span>Project Types</span>
                                            <h4>Website</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="portfolio-thumb-inner">
                                    <div className="thumb position-relative mb--30">
                                        <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                        <ModalVideo channel='youtube' isOpen={isOpen} videoId='ZOoVOfieAF8' onClose={() => setIsOpen(false)} />
                                        <button className="video-popup position-top-center" onClick={openModal}><span className="play-icon"></span></button>
                                    </div>

                                    <div className="thumb mb--30">
                                        <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                    </div>

                                    <div className="thumb">
                                        <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Details */}

            {/* Start Related Work */}
            <div className="portfolio-related-work pb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="theme-color font--18 fontWeight600">Related Work</span>
                                <h2>Our More Projects</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--10">
                        {/* Start Single Portfolio */}
                        {portfolios.map(portfolio => (
                            <div key={portfolio.id} className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <Link to={`/portfolio-details/${portfolio.titleUrl}`}>
                                            <img src={`${portfolio.imageUrl}`} alt="Portfolio-images"/>
                                        </Link>
                                    </div>
                                    <div className="inner">
                                        <h4><Link to={`/portfolio-details/${portfolio.titleUrl}`}>{portfolio.title}</Link></h4>
                                        <span className="category">{portfolio.category}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* End Single Portfolio */}
                    </div>
                </div>
            </div>
            {/* End Related Work */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
<Element name="contact">
            <Footer />
            </Element>

        </React.Fragment>
    )
}
export default PortfolioDetails;
