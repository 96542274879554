import React from "react";

export const AiIcon = ({}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="49.801" height="54.051" viewBox="0 0 49.801 54.051">
            <g id="Group_5" data-name="Group 5" transform="translate(-368 -640.798)">
                <g id="Layer_x0020_1" transform="translate(368 640.798)">
                <path id="Path_11" data-name="Path 11" d="M143.753,156.947a12.043,12.043,0,0,0,.576,1.662,28.827,28.827,0,0,1,1.214,5.947,10.488,10.488,0,0,1-5.069.27c-.211-.954.305-.942.684-1.82l1.082-3.095C142.515,159.126,143.094,157.388,143.753,156.947Zm-8.5,15.962a1,1,0,0,0,1.73.691c.7-.592.737-2.669,1.061-3.717a9.481,9.481,0,0,1,.466-1.175c.884-1.916.166-1.766,3.455-1.766a15.178,15.178,0,0,1,2.113.13c1.29.155,1.242-.21,2.064-.278,1.259,2.379-.214,2.666,1.248,4.272.431.474.706.56,1.718-.113,1.582-1.05-.222-2.288-.534-2.862a8.887,8.887,0,0,1-.792-2.192c-.59-2.254-.382-2.383-1.016-4.952l-.928-3.547a23.751,23.751,0,0,0-.575-2.409,8.757,8.757,0,0,1-.159-1.333,1.546,1.546,0,0,0-2.729-.926c-.337.412-.223.837-.285,1.5a20.551,20.551,0,0,1-.567,3.013c-.4,1.445-1.618,2.691-2.3,5.011-1.166,3.978-2.542,5.644-3.11,7.779a13.2,13.2,0,0,1-.372,1.418,3.749,3.749,0,0,0-.49,1.45Z" transform="translate(-121.847 -137.107)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_12" data-name="Path 12" d="M78.05,456.441A7.682,7.682,0,0,0,79.517,459a8.881,8.881,0,0,0,2.362,1.814c1.3.725,1.574.42,2.779,1.4,1.508,1.225,4.2,1.223,4.862,1.4a14.819,14.819,0,0,0,3.728.449l5.383.9c1.582.3,3.11-2.141,1.165-2.536-.728-.148-.955.125-1.606.125a26.313,26.313,0,0,1-2.806-.326c-2.688-.342-5.9-.137-8.334-1.363a13.778,13.778,0,0,0-2.078-1.055,5.981,5.981,0,0,1-1.191-.45c-.416-.235-.6-.358-1.057-.584-2.35-1.151-2.8-3.459-4.672-2.339Z" transform="translate(-70.315 -410.942)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_13" data-name="Path 13" d="M389.67,42.728c0,2.309,3.16,1.97,4.876,5.416.414.831.665,1.709,1.115,2.465,1.294,2.179,1.781,1.686,2.376,4.486a23.584,23.584,0,0,1,.582,2.849c.323,3.275,2.238,2.285,2.238,1.342l-.508-2.774c-.11-.5-.287-.945-.346-1.3-.321-1.9-.35-2.995-1.865-4.7-1.314-1.478-1.588-3.411-2.326-4.387-.3-.4-.508-.465-.837-.953a7.807,7.807,0,0,0-.641-1c-.937-1.075-1.948-2.049-3.471-2.049-.374,0-1.193.327-1.193.6Z" transform="translate(-351.056 -37.955)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_14" data-name="Path 14" d="M2.951,211.291c1.033,0,2.308-1.082,1.7-1.987-.3-.441-.676-.561-1.068-.886-.633-.523-.849-2.041-.949-2.929-.06-.543-.094-1.111-.173-1.766-.053-.431-.17-1.187-.255-1.685a13.71,13.71,0,0,1-.3-3.279l.211-3.369a3.511,3.511,0,0,0,.236-.957c0-.712-1.26-1.072-1.747-.562l-.616,2.359.126,2.826c0,1.178.064,2.032.141,2.992.164,2.056-.118,3.776.342,5.476.069.255,1.471,3.766,2.351,3.766Z" transform="translate(0.01 -174.444)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_15" data-name="Path 15" d="M111.959,385.6c-.332,1.386.387,1.654,2.275,3.543a9.5,9.5,0,0,0,1.407,1.278,36.072,36.072,0,0,0,5.728,2.328c1.75.549,2.849.012,4.761.012.956,0,3.8.917,3.132-1.939a4.914,4.914,0,0,0-2.238-.3c-1.3,0-.381.6-4.475.6a5.074,5.074,0,0,1-1.447-.492c-.516-.2-.9-.305-1.445-.494a12.4,12.4,0,0,1-4.829-2.928c-.9-.89-1.394-2.178-2.871-1.605Z" transform="translate(-100.794 -347.262)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_16" data-name="Path 16" d="M388.161,327.452c0,.552.763.895,1.343.895.94,0,2.443-2.041,3.194-2.624a16.9,16.9,0,0,0,2.312-2.163c2.615-2.46,3.122-7.324,3.621-8.164.114-.191.57-.755.57-.923,0-1.106-.64-1.343-1.641-1.343-1.726,0-.872,1.7-1.518,4.3a11.735,11.735,0,0,1-.806,2.178,10.443,10.443,0,0,1-1.043,1.94,12.022,12.022,0,0,1-2.877,3.091c-.4.324-3.155,2.312-3.155,2.812Z" transform="translate(-349.695 -282.101)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_17" data-name="Path 17" d="M29.87,41.735a1.616,1.616,0,0,0,.512,1.278c.342.3-.409.214.979.214,1.175,0,.51-.874,2.8-3.318l1.437-1.7c.589-.478,1.284-.786,1.885-1.248a10.43,10.43,0,0,1,2.783-1.841,8.664,8.664,0,0,1,2.029-1.1c.444-.189.665-.339,1.092-.548,1.209-.595,2.3.355,2.3-1.136,0-.839-1.6-.746-2.089-.746a17.784,17.784,0,0,0-4.115,1.851c-1.678,1.039-2.22,1.563-3.521,2.446a20.719,20.719,0,0,0-1.718,1.265l-3.064,2.9c-.29.289-1.305,1.282-1.305,1.679Z" transform="translate(-26.909 -28.459)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_18" data-name="Path 18" d="M106.65,100.957c0,.724.316.925.6,1.343,1.3-.3,2.251-1.661,3.179-2.489,1.2-1.073.279-.706,1.7-1.884a14.107,14.107,0,0,1,2.979-2.094,12.615,12.615,0,0,1,2.052-.783,22.081,22.081,0,0,1,2.45-.392c.494-.142,1.423-1.308-1.912-1.308-2.891,0-3.578,1.143-4.973,1.889a19.946,19.946,0,0,0-3.868,3.144l-2.2,2.575Z" transform="translate(-96.081 -84.1)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_19" data-name="Path 19" d="M328.794,293.05c0,1.216.856-.051-.262,3.468-.324,1.021-.311.355-1.231,1.753-1.443,2.192-1.941,3.049-4.562,3.493-2.566.434-1.155,1.729-.36,1.729,2.678,0,3.481-.917,4.815-2.5.594-.7.983-.471,2.152-2.472l2.132-4.878c0-2.592-2.685-2.043-2.685-.6Z" transform="translate(-289.433 -262.916)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_20" data-name="Path 20" d="M320.42,96.736c0,1.467,2.651.453,4.6,3.455a4.861,4.861,0,0,1,1.125,2.9c.3,1.407,2.331,1.277,2.331.354,0-2.353-1.149-2.871-2.283-4.429a8.369,8.369,0,0,0-1.086-1.45c-1.688-1.663-1.607-1.691-3.718-1.783a.842.842,0,0,0-.967.949Z" transform="translate(-288.668 -86.294)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_21" data-name="Path 21" d="M306.87,243.405c0,.958.331,1.092.593,1.645.546,1.159.193,4.008.241,4.546l.312,1.613c.016.029.039.065.057.092.55.777,2.229.725,2.229-1.781a13.436,13.436,0,0,0-.369-1.87c-.124-.768.167-5.292-1.87-5.292a1.134,1.134,0,0,0-1.193,1.044Z" transform="translate(-276.461 -218.344)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_22" data-name="Path 22" d="M73.632,222.6c0,.942.143.812.035,1.374-.15.775-.456.854.3,1.723a1.081,1.081,0,0,0,.854.459c.7.025,1.5-.47,1.5-1.17,0-1.067-.956-.034-.432-3.714.137-.961,1.51-1.563.584-2.555a1.26,1.26,0,0,0-1.2-.445c-.706,0-1.034,1.115-1.262,1.721a7.184,7.184,0,0,0-.379,2.605Z" transform="translate(-66.195 -196.644)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_23" data-name="Path 23" d="M234.61.895c0,2.147,2.55,1.008,3.992,1.225,1.927.291,2.568,1.649,3.616-.33a2.126,2.126,0,0,0-.972-.818A17.2,17.2,0,0,0,235.654,0C235.052,0,234.61.325,234.61.895Z" transform="translate(-211.361 0)" fill="#f9004d" fill-rule="evenodd"/>
                <path id="Path_24" data-name="Path 24" d="M299.341,190.55a1.951,1.951,0,0,0,1.641,1.791l.652-.541c.649-.7.747-.516,1.138-1.1-.942-1.407-3.432-.879-3.432-.15Z" transform="translate(-269.677 -171.009)" fill="#f9004d" fill-rule="evenodd"/>
                </g>
            </g>
        </svg>

    )
}