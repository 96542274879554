import React, {Component} from "react";
import {FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn} from "react-icons/fa";
import {Link as ScrollLink} from "react-scroll";

const SocialShare = [
    {Social: <FaFacebookF/>, link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn/>, link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram/>, link: 'https://www.instagram.com/'},
    {Social: <FaTwitter/>, link: 'https://twitter.com/'},
]


class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Ready To Do This</span>
                                        <h2>Let's get <br/> to work</h2>
                                        <ScrollLink className="rn-button-style--2" style={{cursor: 'pointer'}}
                                                    to="contact" spy={true}
                                                    smooth={true}
                                                    offset={50}
                                                    duration={500}>
                                            <span>Contact Us</span>
                                        </ScrollLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right bg_color--3" data-black-overlay="6">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <img className="footer-img"
                                                 src="/assets/images/brand/brand-footer.png"
                                                 alt="Logo Image"/>
                                        </div>

                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Quick Link</h4>
                                                <ul className="ft-link">
                                                    <li><ScrollLink style={{cursor: 'pointer'}} to="work" spy={true}
                                                                    smooth={true}
                                                                    offset={50}
                                                                    duration={500}>Work</ScrollLink></li>
                                                    <li><ScrollLink style={{cursor: 'pointer'}} to="about" spy={true}
                                                                    smooth={true}
                                                                    offset={50}
                                                                    duration={500}>About</ScrollLink></li>
                                                    <li><ScrollLink style={{cursor: 'pointer'}} to="contact" spy={true}
                                                                    smooth={true}
                                                                    offset={50}
                                                                    duration={500}>Let's Talk</ScrollLink></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Contact</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:admin@example.com">algorium@outlook.com</a></li>
                                                    <li>
                                                        <a href="">
                                                            C/O Unit 6 Hill View Studios 160 Eltham Hill, London, SE9
                                                            5EA, Birleşik Krallık
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        <div className="col-lg-12">
                                            <div className="row flex-wrap-reverse">
                                                <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                                    <div className="copyright-text">
                                                        <p>Copyright © 2022 Algorium. All Rights Reserved.</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                                    <img
                                                        className="footer-under-img"
                                                        src="/assets/images/brand/brand-footer2.png"
                                                        alt="Logo Image"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}

export default Footer;